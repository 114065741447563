<template>
  <div class="impressions-wrapper pt--120 bg_color--5" id="impressions">
    <v-row>
      <v-col lg="12">
        <div class="text-center section-title pb--30">
          <h2 class="heading-title theme-gradient">Impressionen</h2>
        </div>
      </v-col>
    </v-row>
    <v-container>
      <div class="flex-container">
        <div class="gallery-container" ref="gallery">
          <div class="gallery">
            <div class="im_portfolio" v-for="(item, i) in impressions"
                 :key="i">
              <div class="thumbnail_inner">
                <div class="thumbnail">
                  <a href="#">
                    <img class="w-100" :src="'/api/impression/original/' + item.picturePath" alt="Impressionen"/>
                  </a>
                </div>
              </div>
              <router-link
                  class="transparent_link"
                  to="/portfolio-details"
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import {fetchImpressions} from "@/services/impression-service.ts";

export default {
  async mounted() {
    this.impressions = await fetchImpressions();

    const gallery = this.$refs.gallery;
    // Mouse events
    gallery.addEventListener('mousedown', this.onDragStart);
    gallery.addEventListener('mouseleave', this.onDragEnd);
    gallery.addEventListener('mouseup', this.onDragEnd);
    gallery.addEventListener('mousemove', this.onDragMove);

    // Touch events
    gallery.addEventListener('touchstart', this.onDragStart);
    gallery.addEventListener('touchend', this.onDragEnd);
    gallery.addEventListener('touchmove', this.onDragMove);
  },
  data() {
    return {
      impressions: [],
      isDragging: false,
      startX: 0,
      scrollLeft: 0
    };
  },
  methods: {
    onDragStart(e) {
      this.isDragging = true;
      this.startX = e.pageX || e.touches[0].pageX;
      this.scrollLeft = this.$refs.gallery.scrollLeft;
      e.preventDefault(); // Prevent default behavior to avoid text selection
    },
    onDragEnd() {
      this.isDragging = false;
    },
    onDragMove(e) {
      if (!this.isDragging) return;
      const x = e.pageX || e.touches[0].pageX;
      const walk = (x - this.startX) * 2; // Scroll-Faktor
      this.$refs.gallery.scrollLeft = this.scrollLeft - walk;
    }
  },
  beforeDestroy() {
    const gallery = this.$refs.gallery;
    gallery.removeEventListener('mousedown', this.onDragStart);
    gallery.removeEventListener('mouseleave', this.onDragEnd);
    gallery.removeEventListener('mouseup', this.onDragEnd);
    gallery.removeEventListener('mousemove', this.onDragMove);
    gallery.removeEventListener('touchstart', this.onDragStart);
    gallery.removeEventListener('touchend', this.onDragEnd);
    gallery.removeEventListener('touchmove', this.onDragMove);
  }
};
</script>

<style scoped>
.gallery-container {
  overflow-x: auto;
  white-space: nowrap;
  cursor: grab;
  -webkit-overflow-scrolling: touch;
  user-select: none; /* Verhindert Textmarkierungen beim Scrollen */
}

.gallery-container:active {
  cursor: grabbing;
}

.gallery {
  display: flex;
}

.im_portfolio {
  flex: 0 0 auto; /* Beibehalten der ursprünglichen Größe */
  width: 300px; /* Anpassen der Breite nach Bedarf */
  margin-right: 10px; /* Abstand zwischen den Elementen */
}
</style>