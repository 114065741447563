<template>
  <v-container>
    <v-carousel hide-delimiters v-model="slideIndex" cycle height="500px">
      <v-carousel-item v-for="item in abouts" :key="item.id">
        <v-row no-gutters align="center" class="fill-height">
          <v-col cols="12" md="5" class="d-flex align-center justify-center">
            <v-img :src="'/api/abouts/original/' + item.picturePath" contain class="profile-image"></v-img>
          </v-col>
          <v-col cols="12" md="7" class="info-section">
            <div class="about-inner">
              <div class="section-title">
                <h2 class="heading-title">{{ item.name }}</h2>
                <div class="description">{{ item.description}}</div>
                <div class="purchase-btn">
                  <a class="btn-transparent">
                    {{item.name}} - {{item.rang}}
                  </a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  props: {
    abouts: Array
  },
  data() {
    return {
      slideIndex: 0
    };
  },
};
</script>

<style scoped>
.profile-image {
  max-width: 100%;
  max-height: 100%;
}

.heading-title {
  font-size: 24px; /* Adjust the size as needed */
  margin-bottom: 16px;
}

.description {
  font-size: 16px; /* Adjust the size as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.v-carousel {
  overflow: hidden;
}
</style>
