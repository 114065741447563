<template>
  <div class="about-wrapper" id="aboutus">
    <v-container>
      <v-row align="center" justify="center" no-gutters class="main-row">
        <v-col cols="1" sm="1" class="pagination-controls">
          <v-btn icon @click="prevAbout" :disabled="currentIndex === 0">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" sm="10" md="10" class="content-container">
          <AboutFour v-if="currentAbout" :about="currentAbout" />
        </v-col>
        <v-col cols="1" sm="1" class="pagination-controls">
          <v-btn icon @click="nextAbout" :disabled="currentIndex === aboutUs.length - 1">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AboutNew from "@/components/about/AboutNew.vue";
import { fetchAboutUs } from "@/services/aboutus-service.ts";
import AboutFour from "@/components/about/AboutFour.vue";

export default {
  components: {
    AboutFour,
    AboutNew,
  },
  async mounted() {
    this.aboutUs = await fetchAboutUs();
  },
  data() {
    return {
      aboutUs: [],
      currentIndex: 0,
    };
  },
  computed: {
    currentAbout() {
      return this.aboutUs[this.currentIndex];
    },
  },
  methods: {
    nextAbout() {
      if (this.currentIndex < this.aboutUs.length - 1) {
        this.currentIndex++;
      }
    },
    prevAbout() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
  },
};
</script>

<style scoped>
.about-wrapper {
  padding-top: 120px;
}

.main-row {
  align-items: center;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.v-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.v-btn:hover:not(:disabled) {
  background-color: #000;
  color: #fff;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Ensure the middle column takes the full height */
}

@media (max-width: 600px) {
  .main-row {
    flex-direction: row;
    align-items: center;
  }

  .pagination-controls {
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
}
</style>
