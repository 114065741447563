<template>
  <v-row justify="center">
    <v-dialog
        v-model="value"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-toolbar
            dark
        >
          <v-toolbar-title class="ml-2">Impressum</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                dark
                @click="$emit('input', false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <h6>Angaben gemäß § 5 Telemediengesetz (TMG):</h6>
          Stefan Krone<br>
          Mietkoch<br>
          Carl-Orff-Straße 2 B<br>
          07743 Jena<br>
          Deutschland<br><br><br>

          <h6>Kontakt:</h6>
          Tel.: +49 (0) 1522 194 2099<br>
          E-Mail: info@k13-fooddesign.de<br><br><br>

          <h6>Verantwortlicher für den Inhalt ist gemäß § 55 Abs. 2 Rundfunkstaatsvertrag (RStV):</h6>
          Stefan Krone<br>
          Carl-Orff-Straße 2 B<br>
          07743 Jena<br><br><br>

          <h5>Ausschluss der Haftung:</h5><br>
          <h6>1. Haftung für Inhalte</h6>
          Der Inhalt meiner Internetseite wurde mit größtmöglicher Sorgfalt erstellt. Ich übernehme jedoch keine Gewähr
          dafür, dass dieser Inhalt richtig, vollständig, und aktuell ist und zudem noch gefällt. Gemäß § 7 Abs. 1 TMG
          bin ich für den Inhalt verantwortlich, selbst wenn dieser wurde bestellt.
          Gemäß den §§ 8, 9 und 10 TMG ist für mich keine Verpflichtung gegeben, dass ich Informationen von Dritten, die
          übermittelt oder gespeichert wurden, überwache oder Umstände erhebe, die Hinweise auf nicht rechtmäßige
          Tätigkeiten ergeben.
          Davon nicht berührt, ist meine Verpflichtung zur Sperrung oder Entfernung von Informationen, welche von den
          allgemeinen Gesetzen herrührt.
          Ich hafte allerdings erst in dem Moment, in dem ich von einer konkreten Verletzung von Rechten Kenntnis
          bekomme. Dann wird eine unverzügliche Entfernung des entsprechenden Inhalts vorgenommen.<br><br><br>

          <h6>2. Haftung für Links</h6><br>
          Meine Internetseite enthält Links, die zu externen Internetseiten von Dritten führen, auf deren Inhalte ich
          jedoch keinen Einfluss habe. Es ist mir daher nicht möglich, eine Gewähr für diese Inhalte zu tragen.
          Die Verantwortung dafür hat immer der jeweilige Anbieter/Betreiber der entsprechenden Internetseite. Ich
          überprüfe die von mir verlinkten Internetseiten zum Zeitpunkt der Verlinkung auf einen möglichen Rechtsverstoß
          in voller Breite.
          Es kann mir jedoch, ohne einen konkreten Anhaltspunkt, nicht zugemutet werden, ständig die verlinkten
          Internetseiten inhaltlich zu überwachen. Wenn ich jedoch von einer Rechtsverletzung Kenntnis erlange, werde
          ich den entsprechenden Link unverzüglich entfernen, das kann ich machen.<br><br><br>

          <h6>3. Urheberrecht</h6><br>
          Die auf meiner Internetseite enthaltenen Inhalte sind, soweit möglich, urheberrechtlich geschützt. Es bedarf
          einer schriftlichen Genehmigung des Erstellers für denjenigen, der die Inhalte vervielfältigt, bearbeitet,
          verbreitet oder nützt.<br>
          Das Herunterladen und Kopieren meiner Internetseite ist sowohl für den privaten als auch den kommerziellen
          Gebrauch von mir schriftlich zu gestatten. Ich weise darauf hin, dass ich hinsichtlich der Inhalte auf meiner
          Internetseite, soweit sie nicht von mir erstellt worden sind, das Urheberrecht von Dritten jederzeit beachtet
          hatte.<br>
          Wenn Sie mir mitteilen würden, dass Sie trotzdem eine Urheberrechtsverletzung gefunden haben, würde ich das
          sehr schätzen.<br>
          Dann kann ich den entsprechenden Inhalt sofort entfernen und würde damit das Urheberrecht nicht mehr
          verletzen.<br><br><br>

          <h6>4. Datenschutz</h6><br>
          Meine Internetseite kann regelmäßig ohne die Angabe von personenbezogenen Daten genutzt werden. Falls solche
          Daten (z.B. Name, Adresse oder E-Mail) doch erhoben werden sollten, geschieht das, freiwillig oder nur mit
          ausdrücklicher Zustimmung durch Sie und durch mich zu verbergen.<br>
          Die Übertragung von Daten im Internet ist mit Sicherheitslücken befangen. Es ist daher möglich, dass Dritte
          Zugriff auf diese Daten erlangen. Ein lückenloser Schutz ist nicht möglich, wenn auch löblich.<br>
          Ich widerspreche an dieser Stelle der Nutzung meiner Kontaktdaten, um mir damit nicht verlangte
          Werbung/Informationsmaterial/Spam-Mails zukommen zu lassen. Sollte dies dennoch geschehen, müsste ich
          rechtliche Schritte ins Auge fassen.<br><br><br>
          Angabe der Quelle: Flegl Rechtsanwälte GmbH


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="$emit('input', false)">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Boolean
  }
}
</script>