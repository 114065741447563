<template>
  <v-row class="row--35 content-row" align="start" justify="center">
    <v-col lg="5" md="5" cols="12" class="thumbnail-container">
      <div class="thumbnail">
        <img
            slot="thum-img"
            class="w-100"
            :src="'/api/abouts/original/' + about.picturePath"
            alt="About Images"
        />
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12" class="content-inner-container">
      <div class="about-inner inner">
        <div class="section-title">
          <span class="subtitle">Über uns</span>
          <h2 class="heading-title">{{ about.name }}</h2>
        </div>
        <div class="tab-wrapper mt-3">
          <TabTwo :about="about" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabTwo from "@/components/tabs/TabTwo";

export default {
  props: {
    about: Object,
  },
  components: { TabTwo },
  data() {
    return {};
  },
};
</script>

<style scoped>
.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  order: 1;
}

.content-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  order: 2;
}

.about-inner {
  flex: 1;
  overflow: auto;
}

.thumbnail img {
  max-height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .thumbnail-container {
    order: 1;
  }
  .content-inner-container {
    order: 2;
  }
}
</style>
