<template>
  <div class="single-tab-content single-tab-content-skills">
    <div class="progress-wrapper">
      <div class="rn-progress-charts">
        <h6 class="heading heading-h6">Gastronomische Fachkenntnisse</h6>
        <div class="progress fadeInLeft">
          <div class="progress-bar" :style="'width:' + about.gastroSkill + '%;'">
            <span class="percent-label">{{ '' + about.gastroSkill }}%</span>
          </div>
        </div>
      </div>
      <div class="rn-progress-charts mt--25">
        <h6 class="heading heading-h6">Betriebswirtschaft und Management</h6>
        <div class="progress">
          <div class="progress-bar" :style="'width:' + about.managementSkill + '%;'">
            <span class="percent-label">{{ '' + about.managementSkill }}%</span>
          </div>
        </div>
      </div>
      <div class="rn-progress-charts mt--25">
        <h6 class="heading heading-h6">Ausbildung und Mentoring</h6>
        <div class="progress">
          <div class="progress-bar" :style="'width:' + about.trainingSkill + '%;'">
            <span class="percent-label">{{ '' + about.trainingSkill }}%</span>
          </div>
        </div>
      </div>
      <div class="rn-progress-charts mt--25">
        <h6 class="heading heading-h6">Veranstaltungsmanagement</h6>
        <div class="progress">
          <div class="progress-bar" :style="'width:' + about.eventSkill + '%;'">
            <span class="percent-label">{{ '' + about.eventSkill }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    about: Object
  }
}
</script>