<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item transition="fade-transition" v-for="item in items" :key="item.id">
          <v-card flat>
            <v-card-text>
              <tab-info v-if="item.id === 1" :description="about.description"></tab-info>
              <tab-references v-if="item.id === 2" :references="about.references"></tab-references>
              <tab-skill v-if="item.id === 3" :about="about"></tab-skill>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>

<script>
import TabSkill from "@/components/tabs/tab-skill.vue";
import TabReferences from "@/components/tabs/tab-references.vue";
import TabInfo from "@/components/tabs/tab-info.vue";

export default {
  components: { TabInfo, TabReferences, TabSkill },
  props: {
    about: Object,
  },
  computed: {
    hasReferences() {
      return this.about.references && this.about.references.length > 0;
    },
  },
  data() {
    const itemsInit = [
      {
        id: 1,
        name: "Info",
      },
    ];

    if (this.about.references && this.about.references.length > 0) {
      itemsInit.push({
        id: 2,
        name: "Referenzen",
      });
    }

    itemsInit.push({
      id: itemsInit.length + 1,
      name: "Fähigkeiten",
    });

    return {
      tab: 0,
      items: itemsInit,
    };
  },
};
</script>

<style scoped>
.tab-default {
  width: 100%;
}

.v-tabs {
  justify-content: center;
}

.v-tab {
  text-transform: none;
}

.v-card-text {
  padding: 0;
}
</style>
