<template>
  <div class="home-demo-area inner-page-demo bg_color--1 ptb--120" id="gallery">
    <div class="wrapper plr--120">
      <v-row>
        <v-col lg="12">
          <div class="text-center section-title pb--30">
            <h2 class="heading-title theme-gradient">Galerie</h2>
          </div>
        </v-col>
      </v-row>
      <v-row class="row--0">
        <gallery-container :pictures="gallery"></gallery-container>
      </v-row>
    </div>
  </div>
</template>

<script>
import GalleryContainer from "@/components/gallery/gallery-container.vue";
import {fetchPictures} from "@/services/gallery-service.ts";

export default {
  components: {
    GalleryContainer
  },
  async mounted() {
    this.gallery = await fetchPictures()
  },
  data() {
    return {
      gallery: []
    }
  }
}
</script>