<template>
  <div>
    <v-navigation-drawer
        class="hidden-md-and-up header-one-page-nav-drawer"
        v-model="drawer"
        fixed
        width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img src="../../assets/images/logo/logo_k.png" alt="brand-image">
        </div>
        <v-spacer></v-spacer>
        <v-btn class="close-icon" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>

      <scrollactive
          active-class="v-list-item--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="70"
      >
        <v-list>
          <v-list-item
              :ripple="false"
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              link
              style="text-shadow: 2px 2px 8px rgb(15 48 52)"
              class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
        dark
        color="transparent"
        fixed
        elevate-on-scroll
        class="header-one-page-nav header-one-page-nav-two"
    >
      <router-link to="/" class="logo">
        <img src="../../assets/images/logo/logo_k.png"/>
      </router-link>
      <!-- End brand logo -->

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--35">
        <scrollactive
            ref="scrollactive"
            active-class="v-btn--active"
            bezier-easing-value=".5,0,.35,1"
            :offset="71"
        >
          <v-btn
              v-for="item in items"
              :key="item.title"
              :to="item.to"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              style="text-shadow: 2px 2px 8px rgb(15 48 52)"
          >{{ item.title }}
          </v-btn
          >
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-spacer></v-spacer>
      <div class="social-share-inner">
        <socialTwo/>
      </div>
      <v-btn
          icon
          :ripple="false"
          class="ma-0 pa-0 hidden-md-and-up menu_icon"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import socialTwo from "../social/SocialTwo";

export default {
  components: {
    socialTwo,
  },
  mounted() {
    this.$watch(
        () => {
          return this.$refs.scrollactive?.currentItem
        },
        (val) => {
          if (val) {
            const splittedActive = val.href.split('/');
            console.log(splittedActive);
            if (window.location.hash !== splittedActive) {
              history.pushState(null, null, splittedActive[splittedActive.length - 1]);
            }
          }
        }
    );
  },
  data: () => ({
    drawer: false,
    items: [
      {title: "Home", to: "#home"},
      {title: "Angebote", to: "#menus"},
      {title: "Dienste", to: "#services"},
      {title: "Galerie", to: "#gallery"},
      {title: "Impressionen", to: "#impressions"},
      {title: "Über Uns", to: "#aboutus"},
      {title: "Kontakt", to: "#contact"}
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
