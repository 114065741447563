import Vue from "vue";

const baseURL = '/api';

function getAuthToken() {
    if (Vue.prototype.$keycloak && Vue.prototype.$keycloak.token) {
        return `Bearer ${Vue.prototype.$keycloak.token}`;
    }

    return null;
}

export async function requestAPI(url, options = {}) {
    const fullUrl = `${baseURL}${url}`;
    const authToken = getAuthToken();

    // Define default headers
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    if (authToken) {
        defaultHeaders.Authorization = authToken;
    }

    // Prepare headers, prioritizing options.headers over defaultHeaders when conflict
    // As the Headers constructor and spread operator for headers initialization might not work directly in JS,
    // we need to manually combine the headers from options and the default headers.
    const headers = Object.assign({}, defaultHeaders, options.headers);

    // Merge the provided options with the default fetch options, including headers
    const fetchOptions = Object.assign({}, options, { headers });

    try {
        const response = await fetch(fullUrl, fetchOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
