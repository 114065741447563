import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import {initKeycloak} from './plugins/keycloak';
import VueParticles from "vue-particles";
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import './assets/scss/main.scss'

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(CoolLightBox);
Vue.use(VueScrollactive);

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.protected);
    if (requiresAuth) {
        if (!Vue.prototype.$keycloak) {
            try {
                const keycloak = await initKeycloak();
                Vue.prototype.$keycloak = keycloak;
            } catch (error) {
                console.error("Keycloak Initialization Failed:", error);
            }
        }

        const isAuthenticated = Vue.prototype.$keycloak.authenticated;
        if (!isAuthenticated) {
            // If the route requires authentication and user is not authenticated,
            // redirect to Keycloak login
            Vue.prototype.$keycloak.login();
        } else {
            const hasAuthRoles = to.matched.some(record => record.meta.roles);
            if (hasAuthRoles) {
                const userHasRole = to.meta.roles.some(r => Vue.prototype.$keycloak.hasRealmRole(r));
                if (userHasRole) {
                    next();
                }
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');