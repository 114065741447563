import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "@/views/Home.vue";


Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Home,
        meta: {
            title: 'k13-Fooddesign'
        }
    },
    // {
    //     path: '/admin',
    //     name: 'admin',
    //     component: Admin,
    //     meta: {
    //         title: 'k13 - Admin Panel',
    //         protected: true,
    //         roles: ['k13-fooddesign-admin']
    //     },
    //     children: [
    //         {
    //             path: 'menus',
    //             name: 'admin-menus',
    //             component: AdminMenus,
    //             meta: {
    //                 title: 'k13 - Admin Panel',
    //                 protected: true,
    //                 roles: ['k13-fooddesign-admin']
    //             }
    //         },
    //         {
    //             path: 'services',
    //             name: 'admin-services',
    //             component: AdminServices,
    //             meta: {
    //                 title: 'k13 - Admin Panel',
    //                 protected: true,
    //                 roles: ['k13-fooddesign-admin']
    //             }
    //         },
    //         {
    //             path: 'gallery',
    //             name: 'admin-gallery',
    //             component: AdminGallery,
    //             meta: {
    //                 title: 'k13 - Admin Panel',
    //                 protected: true,
    //                 roles: ['k13-fooddesign-admin']
    //             }
    //         },
    //         {
    //             path: 'aboutus',
    //             name: 'admin-aboutus',
    //             component: AdminAboutus,
    //             meta: {
    //                 title: 'k13 - Admin Panel',
    //                 protected: true,
    //                 roles: ['k13-fooddesign-admin']
    //             }
    //         },
    //     ]
    // },
    {
        path: '*',
        component: Home,
        meta: {
            title: 'k13-Fooddesign'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router