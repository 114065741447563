<template>
  <div class="home-demo-area bg_color--5 ptb--120" id="menus">
    <div class="wrapper plr--120 rn-masonary-wrapper">
      <v-row>
        <v-col lg="12">
          <div class="text-center section-title pb--30 pb_sm--0 pb_md--0">
            <h2 class="heading-title theme-gradient">Angebote</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- Iterates over each menu item and displays a card for it -->
        <v-col style="padding-top: 30px" cols="12" sm="6" md="4" lg="3" v-for="menu in menus" :key="menu.id">
          <v-card class="d-flex flex-column card-height">
            <v-img :src="'/api/menus/original/' + menu.picturePath" height="200px" class="flex-shrink-0"></v-img>
            <v-card-title class="title-height">{{ menu.title }}</v-card-title>
            <v-card-text class="description-height flex-grow-1" style="overflow: auto;">{{
                menu.description
              }}
            </v-card-text>
            <!-- Additional content like card actions can go here -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {fetchMenus} from "@/services/menu-service.ts";

export default {
  async mounted() {
    const menus = await fetchMenus();
    this.menus = menus.map(m => {
      return {...m, active: false};
    });
  },
  data() {
    return {
      menus: []
    }
  }
}
</script>