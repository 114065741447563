<template>
  <div class="prv-feature service-area bg_color--1 ptb--120" id="services">
    <div class="wrapper plr--120 plr_sm--30 plr_md--30 plr_lg--30">
      <v-row>
        <v-col lg="12">
          <div class="text-center section-title pb--30 pb_sm--0 pb_md--0">
            <h2 class="heading-title theme-gradient">Was ich Ihnen bieten kann</h2>
          </div>
        </v-col>
      </v-row>
      <div class="row service-main-wrapper prev-service-main-wrapper d-flex flex-wrap align-stretch">
        <!-- Start Single Service  -->
        <v-col
            xl="3"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="(service, i) in services"
            :key="i"
        >
          <div class="text-left service service__style--2 bg_color--2">
            <div class="icon" v-html="iconSvg(service.symbol)"></div>
            <div class="content">
              <h3 class="heading-title">{{ service.heading }}</h3>
              <p class="subtitle">
                {{ service.content }}
              </p>
            </div>
          </div>
        </v-col>
        <!-- End Single Service  -->
      </div>
    </div>
  </div>
</template>

<script>
import {fetchServices} from "@/services/services-service.ts";
import feather from "feather-icons";

export default {
  async mounted() {
    this.services = await fetchServices()
  },
  data() {
    return {
      services: []
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
  }
}
</script>