<template>
  <v-row align="start" class="row--35">
    <v-col v-if="messageSent" lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="text-left section-title mb--50">
        <h2 v-if="messageSent.ok" class="heading-title theme-gradient">Vielen Dank für Ihre Kontaktanfrage, wir werden Sie in Kürze kontaktieren.</h2>
        <h2 v-else class="heading-title theme-gradient">Beim Senden der Kontaktanfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.</h2>
      </div>
    </v-col>
    <v-col v-else lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="text-left section-title mb--50">
        <h2 class="heading-title theme-gradient">Kontaktiere uns.</h2>
        <div class="im_address">
          <span>Kontakt Email:</span><a class="link im-hover" href="mailto:info@k13-fooddesign.de">info@k13-fooddesign.de</a>
        </div>
      </div>
      <div class="form-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.name"
                  placeholder="Dein Name *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  rules="required|email"
                  v-model="formData.mail"
                  placeholder="Deine E-Mail *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input
                  type="text"
                  v-model="formData.subject"
                  placeholder="Betreff *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea
                  v-model="formData.message"
                  placeholder="Deine Nachricht *"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button class="btn-default" type="submit" value="submit">
              Absenden
            </button>
          </form>
        </ValidationObserver>
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <slot name="contact-img"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {postContactForm} from "@/services/contact-service.ts";

export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        messageSent: null,
        formData: {
          name: "",
          mail: "",
          subject: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        postContactForm(this.formData).then(resp => {
          this.messageSent = resp;
        });
      },
    },
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
</style>
