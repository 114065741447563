<template>
  <div class="single-tab-content">
    <ul>
      <li v-for="reference in references" :key="reference">
        {{ reference }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    references: Array
  }
}
</script>