import {requestAPI} from "@/api";

const BASE_PATH = '/menus';

export const fetchMenus = async () => {
    try {
        const response = await requestAPI(BASE_PATH);
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch Menus:", error);
        return [];
    }
};

export const fetchMenu = async (id) => {
    try {
        const response = await requestAPI(`${BASE_PATH}/${id}`);
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Failed to fetch Menu for id: ${id}`, error);
        return null;
    }
};

export const updateOrCreateMenu = async (menu) => {
    const response = await requestAPI(BASE_PATH, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(menu)
    });
    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }
};

export const deleteOneMenu = async (id) => {
    const response = await requestAPI(`${BASE_PATH}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
    }
};

