<template>
  <!-- Start Footer Style Two  -->
  <div class="footer-style-2 ptb--30 bg_color--5" data-black-overlay="6">
    <div class="wrapper plr--50 plr_sm--20">
      <v-row align="center">
        <v-col lg="4" md="4" sm="6" cols="12">
          <div class="inner">
            <div class="text-center d-block logo text-sm-left mb_sm--20">
              <router-link to="/"
                ><img :src="logo" alt="Logo images"
              /></router-link>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="6" cols="12">
          <div class="text-center inner">
            <ul class="justify-center social-share d-flex liststyle">
              <li v-for="(social, i) in socialList" :key="i">
                <a :href="social.url" target="_blank"
                  ><i class="fab" :class="social.icon"></i
                ></a>
              </li>
            </ul>
            <div class="im_address mt-1">
              <a class="link im-hover" @click="showImpressum">Impressum</a>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col lg="4" md="4" sm="12" cols="12">
          <div class="text-center inner text-md-right mt_md--20 mt_sm--20">
            <div class="text">
              <p>
                Copyright © {{ new Date().getFullYear() }} <a class="copyright_txt" href="/">k13-fooddesign.de</a> All
                Rights Reserved.
              </p>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
      <impressum-component v-model="showImpressumDialog"></impressum-component>
    </div>
  </div>
  <!-- End Footer Style Two  -->
</template>

<script>
import ImpressumComponent from "@/components/impressum/impressum.component.vue";

export default {
    components: {
      ImpressumComponent
    },
    methods: {
      showImpressum() {
        this.showImpressumDialog = !this.showImpressumDialog;
      }
    },
    data() {
      return {
        showImpressumDialog: false,
        logo: require("../../assets/images/logo/logo_k.png"),
        socialList: [
          {
            icon: "fa-facebook-f",
            url: "https://www.facebook.com/",
          },
          {
            icon: "fa-linkedin-in",
            url: "https://www.linkedin.com/in/stefan-krone-4743532b4/",
          },
          {
            icon: "fa-twitter",
            url: "https://twitter.com/",
          },
          {
            icon: "fab fa-instagram",
            url: "https://www.instagram.com/mein_kipper_und_ich/",
          },
        ],
      };
    },
  };
</script>
