import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: 'http://localhost:8090',
    realm: 'k13-fooddesign',
    clientId: 'frontend'
};

const keycloak = new Keycloak(keycloakConfig);

const initKeycloak = () => {
    return new Promise((resolve, reject) => {
        keycloak.init({
            onLoad: 'check-sso',
            responseMode: 'fragment'
        }).then(() => {
            resolve(keycloak);
        }).catch(error => {
            console.error(error);
            reject(error);
        });
    });
};

export {initKeycloak, keycloak};
