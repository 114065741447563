<template>
  <v-container>
    <v-row>
      <v-col style="padding-top: 30px" cols="12" sm="6" md="4" v-for="(image, index) in pictures" :key="index">
        <v-hover v-slot="{ hover }">
          <v-card @click="openDialog(image)" class="mx-auto" flat tile>
            <v-img :src="'/api/gallery/thumb/' + image.thumbPath" class="grey lighten-2" height="200px"
                   aspect-ratio="1.5">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
              <v-expand-transition>
                <div v-if="hover" class="overlay-content">
                  <div class="overlay-title py-2 px-4">{{ image.name }}</div>
                </div>
              </v-expand-transition>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>

    <!-- Zoomed Image Dialog -->
    <v-dialog v-if="selectedImage" v-model="dialog" max-width="600px">
      <v-card>
        <v-img :src="'/api/gallery/original/' + selectedImage.picturePath" aspect-ratio="1.5"></v-img>
        <v-card-title>{{ selectedImage.name }}</v-card-title>
        <v-card-text>{{ selectedImage.description }}</v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    pictures: Array
  },
  data() {
    return {
      selectedImage: null,
      dialog: false
    }
  },
  methods: {
    openDialog(image) {
      this.selectedImage = image;
      this.dialog = true;
    }
  }
}
</script>

<style>
.overlay-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-title {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
}
</style>