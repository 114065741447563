<template>
  <!-- Start Contact Area  -->
  <div class="rn-contact-area ptb--120 bg_color--1" id="contact">
    <div class="contact-form--1">
      <v-container>
        <contact-form>
          <img
              slot="contact-img"
              src="../../assets/images/logo/logo_skyline.png"
              alt="contact images"
          />
        </contact-form>
      </v-container>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/contact/contact-form.vue";

export default {
  components: {
    ContactForm
  }
}
</script>