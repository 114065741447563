import {requestAPI} from "@/api";

export const postContactForm = async (contactForm) => {
    try {
        const response = await requestAPI('/mail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactForm)
        });
        return response;
    } catch (error) {
        console.error("Failed to post contact message:", error);
        return []; // Return an empty array in case of an error
    }
};
