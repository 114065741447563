<template>
  <div class="active-dark bg_color--9">
    <!-- Start Header Area -->
    <HeaderOnePageTwo/>
    <!-- End Header Area -->

    <home-component></home-component>
    <menus-component></menus-component>
    <services-component></services-component>
    <gallery-component></gallery-component>
    <impressions-component></impressions-component>
    <about-component></about-component>
    <contact-component></contact-component>

    <!-- Start Footer Area  -->
    <Footer/>
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "@/components/header/HeaderOnePageTwo";
import Contact from "@/components/contact/contact-form.vue";
import Footer from "@/components/footer/FooterTwo";
import HomeComponent from "@/components/home/home.component.vue";
import ServicesComponent from "@/components/services/services.component.vue";
import MenusComponent from "@/components/menus/menus.component.vue";
import AboutComponent from "@/components/about/about.component.vue";
import GalleryComponent from "@/components/gallery/gallery.component.vue";
import ContactComponent from "@/components/contact/contact.component.vue";
import ImpressionsComponent from "@/components/impressions/impressions.component.vue";

export default {
  components: {
    ImpressionsComponent,
    ContactComponent,
    GalleryComponent,
    AboutComponent,
    MenusComponent,
    ServicesComponent,
    HomeComponent,
    HeaderOnePageTwo,
    Contact,
    Footer,
  }
};
</script>
